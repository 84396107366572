import React, { Fragment, useEffect } from "react";
import { Checkbox, InputLabel, withStyles } from '@material-ui/core';

const CustomCheckboxColor = withStyles({
  root: {
    color: "rgba(0, 0, 0, 0.54)",
    "&$checked": {
      color: "inherit"
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

function Filter(props) {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filterParams = params.get('filter');
    props.setParamTags(filterParams?.split('&').join(',') || '');
    const initialSelectedCategories = {};

    if (filterParams) {
      filterParams.split('&').forEach(id => {
        initialSelectedCategories[id] = true;
      });
    }

    props.setSelectedCategories(initialSelectedCategories);
  }, [props.setSelectedCategories]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const checkedFilters = [];

    props.filters.forEach(filter => {
      if (props.selectedCategories[filter.id]) {
        checkedFilters.push(filter.id);
      }
    });

    if (checkedFilters.length > 0) {
      params.set('filter', checkedFilters.join('&'));
    } else {
      params.delete('filter');
    }

    params.set('page', '1');
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, '', newUrl);
  }, [props.selectedCategories, props.filters]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    props.toggleFilter(event);

    const params = new URLSearchParams(window.location.search);
    const checkedFilters = [];

    props.filters.forEach(filter => {
      if (filter.id === value && checked) {
        checkedFilters.push(filter.id);
      } else if (props.selectedCategories[filter.id] && filter.id !== value) {
        checkedFilters.push(filter.id);
      }
    });

    if (checkedFilters.length > 0) {
      params.set('filter', checkedFilters.join('&'));
    } else {
      params.delete('filter');
    }

    params.set('page', '1');
    props.setParamTags(checkedFilters.join(','));
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

  return (
    props.filters.length >= 1 &&
      <>
        <div className="blog-index-filters">
          <InputLabel className="blog-index-filters-title" color="secondary"><b>{props.name}</b></InputLabel>
          {props.filters.map((filter) => {
            const isChecked = !!props.selectedCategories[filter.id]; // Ensure isChecked is always a boolean
            return <div key={filter.id}>
              <CustomCheckboxColor
                value={filter.id}
                onChange={handleCheckboxChange}
                checked={isChecked}
              />
              <span>{htmlDecode(filter.name)}</span>
            </div>
          })}
        </div>
        <br />
      </>
  );
}

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export default Filter;